<template>
  <div class="form-group">
    <div class="input-group input-group-image-upload">
      <label class="file-upload-label">
        <slot name="title">Image Upload</slot>
      </label>
      <vuestic-file-upload
        type="single"
        :dropzone="true"
        :fileTypes="types"
        v-model="fileUploadList"
      />

      <div class="image-upload-preview" v-if="fileUrl">
        <img :src="fileUrl">
        <div class="actions text-center">
          <button class="btn btn-primary btn-sm align-middle" @click.prevent="deleteFile">Delete</button>
        </div>
      </div>
    </div>

    <div class="align-self-center overlay-dim" v-if="spinner.active">
      <atom-spinner :animation-duration="1000" :size="120" :color="spinner.color" />
    </div>
  </div>
</template>

<script>
  import VuesticFileUpload from "../../vuestic-theme/vuestic-components/vuestic-file-upload/VuesticFileUpload";

  export default {
    components: {
      VuesticFileUpload,
    },
    name: 'app-image-upload',
    props: {
      file: {
        type: String,
        default: null
      },
      types: {
        type: String,
        default: '.png, .jpg, .jpeg, .gif, .webp'
      },
    },
    mounted() {
      this.fileUrl = this.file;
    },
    data() {
      return {
        fileUrl: null,
        fileUploadList: [],
        fileUploadLoading: false,
        spinner: {
          active: false,
          color: '#ffffff'
        },
      }
    },
    watch: {
      file (n, o) {
        this.fileUrl = n;
      },
      fileUploadList (n, o) {
        this.uploadFile();
      },
    },
    methods: {
      uploadFile() {
        const image = (this.fileUploadList.length) ? this.fileUploadList[this.fileUploadList.length - 1] : null;
        if (!(image instanceof File)) return;

        this.spinner.active = true;

        let event = {};
        let promise = Promise.resolve();

        event.image = image;
        event.waitUntil = p => promise = p;
        this.$emit('upload', event);

        promise.then((result) => {

          this.fileUrl = result;
          this.fileUploadList = [];
          this.spinner.active = false;
        })
      },

      deleteFile() {
        if (!this.fileUrl) return;

        this.spinner.active = true;

        let event = {};
        let promise = Promise.resolve();

        event.waitUntil = p => promise = p;
        this.$emit('delete', event);

        promise.then((result) => {

          this.fileUrl = null;
          this.fileUploadList = [];
          this.spinner.active = false;
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .vuestic-file-upload-field--dropzone {
    padding: 0 .7rem !important;
    height: 2.4rem !important;
  }

  .input-group-company-logo {
    .vuestic-file-upload-single {
      height: 60px;

      button {
        display: none;
      }
    }
  }

  .image-upload-preview {
    img {
      max-width: 100%;
      max-height: 100px;
      margin: 5px auto;
    }
    .actions {
      text-align: right;
    }
  }

  .overlay-dim {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;

    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right:0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .file-upload-label {
    display: block;
    text-align: left;
    clear: both;
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
  }
</style>
