<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <ValidationObserver v-slot="{ handleSubmit }" ref="settingForm">
          <form @submit.prevent="handleSubmit(save)">
            <fieldset class="mt-3">
              <legend>Company info</legend>
              <div class="row">
                <div class="col-md-4">
                  <text-input name="company-info-name" v-model="form.name" label="Name" />
                </div>
                <div class="col-md-4">
                  <text-input name="company-info-address" v-model="form.properties.whiteLabel.company.address"
                    label="Address" />
                </div>
                <div class="col-md-4">
                  <phone-number-input name="phone_number" v-model="form.properties.incomingCallsPhoneNumber"
                    label="Phone Number" />
                </div>
              </div>
            </fieldset>

            <fieldset class="mt-3">
              <legend>Twilio</legend>

              <p>Twilio Information not editable after setup. Contact Support if you need to change these values</p>
              <div class="row">
                <div class="col-md-6">
                  <div class="control-label">Twilio Account SID</div>
                  <span class="api-key-span">{{ twilioConfig.aSID }}</span>
                  <!-- <text-input name="token" v-model="twilioConfig.aSID" label="Twilio Account SID" :disabled="true"/> -->
                </div>
                <div class="col-md-6">
                  <div class="control-label">Twilio Auth Token</div>
                  <span class="api-key-span">{{ twilioConfig.token }}</span>
                  <!-- <text-input name="token" v-model="twilioConfig.token" label="Twilio Auth Token" :disabled="true"/> -->
                </div>
                <div class="col-md-6 mt-5">
                  <div class="control-label has-tool-tip pb-1">Primary Profile SID <a href="javascript:;"
                      class="fa fa-question-circle tooltip-icon" @click="onHelpProfileSid" /></div>
                  <text-input name="token" v-model="form.properties.twPrimaryProfileSid" />
                </div>
              </div>
            </fieldset>

            <fieldset class="mt-3">
              <legend>Stripe</legend>

              <div class="row">
                <div class="col-md-6">
                  <div class="control-label">Stripe Public Key</div>
                  <span class="api-key-span">{{ stripeConfigCurrent.publicKey }}</span>
                </div>
                <div class="col-md-6">
                  <div class="control-label">Stripe Secret Key</div>
                  <span class="api-key-span">{{ stripeConfigCurrent.secretKey }}</span>
                </div>
                <div class="col-md-12 mt-3 d-flex" v-if="!hasStripeConfig">
                  <button type="button" class="btn btn-primary d-block ml-auto" @click="onOpenStripeModal">Update Stripe
                    Key</button>
                </div>
              </div>
            </fieldset>

            <fieldset class="mt-3">
              <legend>White Label</legend>
              <!-- whitelabel: dns setup instruction -->
              <a class="link" href="javascript: void(0)" @click.prevent="openModalInstructionsSetupDNS">(Don't Forget To
                Set Up Your DNS!)</a>
              <br />
              <div class="row mt-4 mb-4">
                <div class="col-md-6">
                  <text-input validate="customurl" name="whitelabel.domain" v-model="form.whitelabel.domain"
                    label="Custom URL"
                    tooltip="Place your main domain or subdomain, main domain covers `www` subdomain as well" />
                </div>
                <div class="col-md-3">
                  <text-input name="subdomain_url" v-model="form.subdomain_url" label="Subdomain URL" />
                </div>
                <div class="col-md-3 pt-4">
                  <a class="link" target="_blank" :href="getSubdomainURL">{{ getSubdomainURL }}</a>
                </div>
                <div class="col-md-6">
                  <text-input type="email" name="whitelabel-support-email"
                    v-model="form.properties.whiteLabel.support_email" label="Support Email" />
                </div>
                <div class="col-md-6">
                  <phone-number-input name="Support Phone" v-model="form.properties.whiteLabel.support_phone"
                    :required="false" label="Support Phone" />
                </div>
                <div class="col-md-6">
                  <textarea-input max-length="300" name="whitelabel-calendly-url"
                    v-model="form.properties.whiteLabel.calendly_url" label="Onboarding Calendly URL" />
                </div>
                <div v-if="isMainAgency" class="col-sm-6">
                  <text-input name="order_bump" v-model="form.properties.whiteLabel.wgo_price"
                    label="WhiteGlove Price Id" />
                </div>
              </div>
              <br />

              <div class="row">
                <!-- Agency Header Logo -->
                <div class="col">
                  <app-image-upload @upload="uploadHeaderLogo" @delete="deleteHeaderLogo"
                    :file="getImageUrl('headerLogo')">
                    <span slot="title">Header Logo ( 250 x 50 )</span>
                  </app-image-upload>
                </div>


                <!-- Agency Login Page Logo -->
                <div class="col">
                  <app-image-upload @upload="uploadLoginPageLogo" @delete="deleteLoginPageLogo"
                    :file="getImageUrl('loginPageLogo')">
                    <span slot="title">Login Page Logo ( 250 x 50 )</span>
                  </app-image-upload>
                </div>

                <!-- Agency Favicon -->
                <div class="col">
                  <app-image-upload @upload="uploadFavicon" @delete="deleteFavicon" :file="getImageUrl('favicon')"
                    types=".png, .jpg, .jpeg, .gif, .ico">
                    <span slot="title">Favicon ( 128 x 128 )</span>
                  </app-image-upload>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-3">
                  <div class="control-label mt-2 mb-2">Show Landing Page</div>
                  <vuestic-switch v-model="form.properties.whiteLabel.landingPage" :offcolor=true>
                    <span slot="trueTitle">Yes</span>
                    <span slot="falseTitle">No</span>
                  </vuestic-switch>
                </div>
              </div>

              <fieldset class="mt-3">
                <legend>Powered By</legend>
                <p>Text about "Powered By" feature / usage</p>
                <div class="row">
                  <div class="col-md-3">
                    <div class="control-label mt-2 mb-2">Enabled</div>
                    <vuestic-switch v-model="form.properties.whiteLabel.poweredBy.enabled" :offcolor=true>
                      <span slot="trueTitle">Yes</span>
                      <span slot="falseTitle">No</span>
                    </vuestic-switch>
                  </div>

                  <div class="col-md-5" v-if="form.properties.whiteLabel.poweredBy.enabled">
                    <text-input name="powered-by-url" v-model="form.properties.whiteLabel.poweredBy.url" label="Url" />
                  </div>
                  <div class="col-md-3" v-if="form.properties.whiteLabel.poweredBy.enabled">
                    <text-input name="powered-by-url" v-model="form.properties.whiteLabel.poweredBy.name"
                      label="Name" />
                  </div>
                </div>
              </fieldset>
            </fieldset>
          </form>
        </ValidationObserver>
        <div class="row">
          <div class="col-md-12 mt-3">
            <button @click="save" :disabled="processing" class="btn btn-primary d-block ml-auto mr-auto">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalInstructionsSetupDNS" :cancelShown="false" @close="closeModalInstructionsSetupDNS"
      okText="Got It !">
      <span slot="title">DNS SETUP</span>
      <br />To use your own domain/subdomain, you will need to update your DNS as follows:
      <br />
      <br /><b>To use a dedicated top level domain</b> (e.g. yourdomain.com ), change it's <strong>A</strong> value to
      ours:
      <br />{{ $store.state.app.sitedata.whitelabel_ip }}
      <br />
      <br /><b>To use a subdomain</b> (e.g. reviews.yourdomain.com), create an <strong>A</strong> record under the domain,
      like so:
      <table>
        <tr>
          <th><b>Host Target</b></th>
          <th>&nbsp;</th>
          <th><b>Your Subdomain Value</b></th>
        </tr>
        <tr>
          <td>reviews.yourdomain.com</td>
          <td>&nbsp;</td>
          <td>{{ $store.state.app.sitedata.whitelabel_ip }}</td>
        </tr>
      </table>
      <br />
      <p><i>Please contact <a class="link" href="mailto:support@ringbot.io">support@ringbot.io</a> if SSL doens't work on
          your custom domain in an hour after you submit request.</i></p>
    </vuestic-modal>
    <vuestic-modal :isOpen="isStripeModalOpen" @cancel="isStripeModalOpen = false" :cancelShown="false" :okShown="false">
      <span slot="title">Add/Update Your Stripe Credential</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(onStripeUpdate)">
          <div class="row">
            <div class="col-md-12">
              <text-input name="PublicKey" v-model="stripeConfig.publicKey" label="Stripe Public Key"
                :validate="'required'" />
            </div>
            <div class="col-md-12">
              <text-input name="SecretKey" v-model="stripeConfig.secretKey" label="Stripe Secret Key"
                :validate="'required'" />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary mr-2" :disabled="stripeLoading || (invalid && dirty)">
                <atom-spinner v-if="stripeLoading" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                <span v-else>Update</span>
              </button>
              <button type="button" @click="closeModalStripe()" class="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal large :isOpen="isOpenModalProfileSid" @cancel="isOpenModalProfileSid = false" :cancelShown="false"
      :okShown="false">
      <span slot="title" class="text-primary font-weight-bold">How to get Primary Profile SID</span>
      <div class="row">
        <div class="col-md-12">
          <p><a href="https://console.twilio.com/us1/account/trust-hub/customer-profiles" target="_blank"
              class="text-primary"><u>Click Here</u></a> to access profiles page.</p>
          <p>( <b class="text-primary">Create Primary Business Profile</b> if you don't have. If you are on sub account, you need to switch to parent account because Primary Profile SID is visible to parent account only  )</p>
          <p>Click <b class="text-primary">View profile details</b> button under Buiness Profiles section.</p>
          <p>In detail page, you can find <b class="text-primary">Business Profile SID</b>.</p>
          <p>( start with `BU`. &nbsp;&nbsp;e.g <code>BUxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</code> )</p>
          <img src="@/assets/images/profile-help.webp" alt="Profile Help" width="100%">
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import merge from "lodash/merge";
import TwilioNumberSelector from '../../common/TwilioNumberSelector'
import AppImageUpload from "../../common/ImageUpload";
import { defaultAgency } from '../../../constants'

export default {
  name: "agency-information",
  components: {
    AppImageUpload,
    TwilioNumberSelector,
  },

  created() {
    this.initData()
  },
  mounted() {
    if (this.form.whitelabel.domain && this.form.whitelabel.domain.length) {
      //document.getElementById('whitelabel-record').setAttribute('disabled', 'disabled');
      this.checkDisabled = true;
    }
    this.twilioConfig = {
      aSID: this.agency.twilioConfig && this.agency.twilioConfig.aSID,
      token: this.agency.twilioConfig && this.agency.twilioConfig.token
    }
  },
  computed: {
    ...mapGetters(["palette"]),

    ...mapGetters("auth", {
      isMainAgency: "isMainAgency",
    }),

    user() {
      return this.$store.getters['auth/user']
    },

    agency() {
      return this.user && this.user.agency
    },

    getSubdomainURL() {
      return 'https://' + this.form.subdomain_url + '.' + this.$store.state.app.sitedata.whitelabel_domain;
    },

    hasStripeConfig() {
      return this.agency.stripeConfig && this.agency.stripeConfig.publicKey && this.agency.stripeConfig.secretKey
    },

    stripeConfigCurrent() {
      let publicKey = '',
        secretKey = '';

      if (this.$store.state.auth.user && this.agency.stripeConfig) {
        publicKey = this.agency.stripeConfig.publicKey || ''
        secretKey = this.agency.stripeConfig.secretKey || '';
      }

      return {
        publicKey: publicKey,
        secretKey: secretKey,
      }
    },
  },
  // @todo need to eventually remove these default values
  data() {
    return {
      isOpenModalRestrict: false,
      isOpenModalProfileSid: false,
      isStripeModalOpen: false,
      stripeLoading: false,
      loading: false,
      processing: false,
      checkDisabled: false,
      isOpenModalInstructionsSetupDNS: false,
      twilioConfig: {
        aSID: null,
        token: null
      },
      stripeConfig: {
        publicKey: null,
        secretKey: null,
      },
      initStripeConfig: {
        publicKey: null,
        secretKey: null,
      },
      form: {},
      formTemplate: JSON.parse(JSON.stringify(defaultAgency)),
    };
  },

  methods: {
    initData() {
      this.form = merge(
        this.formTemplate,
        { ...this.agency }
      )
    },

    onHelpProfileSid() {
      this.isOpenModalProfileSid = true
    },

    onStripeUpdate() {
      this.stripeLoading = true;
      this.$store.dispatch('agency/updateStripeSetting', { id: this.form.id, data: this.stripeConfig })
        .then((res) => {
          this.isStripeModalOpen = false;
          this.stripeLoading = false;
        })
        .catch((errors) => {
          this.stripeLoading = false;
        })
    },

    closeModalStripe() {
      this.isStripeModalOpen = false;
    },

    onOpenStripeModal() {
      this.stripeConfig = { ...this.initStripeConfig }
      this.isStripeModalOpen = true;
    },

    disableField() {
      this.isOpenModalRestrict = false;
    },
    OnBlurInput() {
      this.isOpenModalRestrict = true;
    },
    save() {
      this.$refs.settingForm.validate().then(valid => {
        if (valid) {
          this.processing = true;
          this.$store.dispatch('agency/updateSettings', { id: this.form.id, data: this.form })
            .then((res) => {
              this.form = {
                ...res.data.data
              }
              this.processing = false;
            })
            .catch(errors => {
              this.processing = false;
              if (errors.response.data) {
                this.$nextTick(() => {
                  this.$refs.settingForm.setErrors(errors.response.data.errors)
                })
              }
            })
        } else {
          this.scrollToError(this.$refs.settingForm)
        }
      });
    },

    openModalInstructionsSetupDNS() {
      this.isOpenModalInstructionsSetupDNS = true;
    },
    closeModalInstructionsSetupDNS() {
      this.isOpenModalInstructionsSetupDNS = false;
    },

    getImageUrl(type) {
      let result = null;
      if (
        this.form &&
        this.form.properties &&
        this.form.properties.images &&
        type in this.form.properties.images
      ) {
        result = this.form.properties.images[type].url;
      }
      return result;
    },

    setImageProperty(type, image) {
      if (!this.form) this.form = {};
      if (!this.form.properties) this.form.properties = {};
      if (!this.form.properties.images) this.form.properties.images = {};
      this.form.properties.images[type] = image;
      return this;
    },

    deleteImageProperty(type) {
      if (
        this.form &&
        this.form.properties &&
        this.form.properties.images &&
        type in this.form.properties.images
      ) {
        delete this.form.properties.images[type]
      }
      return this;
    },

    uploadFavicon(event) {
      this.uploadImage(event, 'favicon');
    },
    deleteFavicon(event) {
      this.deleteImage(event, 'favicon');
    },
    uploadHeaderLogo(event) {
      this.uploadImage(event, 'headerLogo');
    },
    deleteHeaderLogo(event) {
      this.deleteImage(event, 'headerLogo');
    },
    uploadLoginPageLogo(event) {
      this.uploadImage(event, 'loginPageLogo');
    },
    deleteLoginPageLogo(event) {
      this.deleteImage(event, 'loginPageLogo');
    },


    uploadImage(event, type) {
      event.waitUntil(new Promise((resolve, reject) => {
        let postData = new FormData();
        postData.append('image', event.image);
        postData.append('type', type);
        let params = {
          id: this.form.id,
          data: postData
        }
        this.processing = true
        this.$store.dispatch('agency/updateImage', params)
          .then((data) => {
            this.processing = false
            if (data.success) {
              const image = data.image;
              this.setImageProperty(type, image);
              resolve(image.url);
            } else {
              reject();
            }
          })
          .catch((e) => {
            this.processing = false;
            console.log(e);
            reject(e);
          })
      }))
    },

    deleteImage(event, type) {
      event.waitUntil(new Promise((resolve, reject) => {
        let params = {
          id: this.form.id,
          type: type
        }
        this.processing = true
        this.$store.dispatch('agency/deleteImage', params)
          .then((data) => {
            this.processing = false
            if (data.success) {
              this.deleteImageProperty(type);
              resolve();
            } else {
              reject();
            }
          })
          .catch((e) => {
            this.processing = false;
            console.log(e);
            reject(e);
          })
      }))
    }
  }
};
</script>

<style lang="scss" scoped>
div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}

.form-group {
  .control-label {
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -.6rem;
    left: 0;
  }
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}

.vuestic-switch {
  width: 100%;
}
</style>
<style lang="scss">
.input-group-image-upload {
  .vuestic-file-upload {
    .vuestic-file-upload-field--dropzone {
      height: 4rem;
    }
  }

  .image-upload-preview {
    height: 88px;
    text-align: center;

    img {
      max-height: 40px;
    }

    .actions {
      text-align: center;
    }
  }
}

.vuestic-modal {
  .modal-body {

    b,
    strong {
      font-weight: 600;
    }
  }
}

.api-key-span {
  font-size: 17px;
  color: #333333;
  display: block;
  padding: 10px 0 0;
  border-bottom: 1px dashed #666;
}
</style>
